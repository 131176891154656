export const ROW_GUTTER = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };
export const BOX_SHADOW =
  '0px 12px 17px rgba(2, 3, 3, 0.03), 0px 5px 22px rgba(2, 3, 3, 0.02), 0px 7px 8px rgba(2, 3, 3, 0.04)';

export const CURRENT_LANG = window?.electionApp?.currentLang || 'fi';
export const NAME_SEARCH = window?.electionApp?.pllName || 'Nimi';
export const MUNICIPALITY_SEARCH =
  window?.electionApp?.pllMunicipality || 'Kunta';
export const MUNICIPALITY_SEARCH_HOLDER =
  window?.electionApp?.pllMunicipalityHolder || 'Kunta';
export const PARTY_FILTER = window?.electionApp?.pllPartyFilter || 'Puolue';
export const CHOOSE_PARTY =
  window?.electionApp?.pllChooseParty || 'Valitse puolue';
export const ALL = window?.electionApp?.pllAll || 'Kaikki';
export const UNION_FILTER =
  window?.electionApp?.pllUnionFilter || 'Ammattiliitto';
export const CHOOSE_UNION =
  window?.electionApp?.pllChooseUnion || 'Valitse ammattiliitto';
export const SHARE_TEXT = window?.electionApp?.pllShare || 'Jaa';
export const SHARE_LINK =
  window?.electionApp?.pllShareLink || 'Jaa somessa tai kopioi linkki';
export const INTRO_TEXT =
  window?.electionApp?.pllIntroduction || 'Esittelyteksti';
export const INTRO_TEXT_SV =
  window?.electionApp?.pllIntroductionSv || 'Presentationstext';
export const INTRO_TEXT_EN =
  window?.electionApp?.pllIntroductionEn || 'Introduction text';
export const COPY_LINK = window?.electionApp?.pllCopyLink || '';
export const LINK_COPIED = window?.electionApp?.pllLinkCopied || '';
export const THANKS_TEXT = window?.electionApp?.pllThanks || 'Kiitos';
export const PRINT_FILENAME =
  window?.electionApp?.pllPrintFileName || 'Ehdokastuloste';
export const WP_ENV = window?.electionApp?.wpEnv || 'development';
export const NO_RESULTS_TEXT =
  window?.electionApp?.pllNoResults || 'Ei hakuehtoja vastaavia tuloksia';
export const GO_BACK_TEXT =
  window?.electionApp?.pllGoBack || 'Takaisin hakutuloksiin';
export const CHOOSE_COUNTY =
  window?.electionApp?.pllChooseCounty || 'Valitse alue';
export const COUNTY = window?.electionApp?.pllCounty || 'Hyvinvointialue';
