import { SAVE_CANDIDATES } from './actions';

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CANDIDATES:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
