/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { LOAD_CANDIDATES, SAVE_CANDIDATES } from './actions';

// check if url includes "useShadow"-parameter
const useShadow = window.location.search.includes('useShadow');
// URL and post per page
// const API_URL = 'https://kuntavaalikone.fi';
const API_URL = '';
const baseUrl = '/wp-json/wp/v2/posts';
const shadow =
  process.env.NODE_ENV !== 'production' || useShadow
    ? `?seravo_shadow=a3f942`
    : '';
const perPage =
  process.env.NODE_ENV !== 'production' ? '&per_page=100' : '?per_page=100';
const ehdokkaanaAluevaaleissa = 'olen_ehdokkaana_aluevaaleissa=true';
const options = {
  wpFetchHeaders: {
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Expose-Headers': 'x-wp-total',
    },
  },
};

async function getNumCandidates() {
  const { headers } = await axios.get(
    `${API_URL}${baseUrl}${shadow}${perPage}&${ehdokkaanaAluevaaleissa}`,
    options.wpFetchHeaders
  );
  return headers['x-wp-totalpages'];
}

// Loop all pages and store candidates
async function fetchPosts(numPages) {
  const posts = [];

  for (let page = 1; page <= numPages; page += 1) {
    const post = axios.get(
      `${API_URL}${baseUrl}${shadow}${perPage}&page=${page}&${ehdokkaanaAluevaaleissa}&skip_cache=true`,
      options.wpFetchHeaders
    );
    posts.push(post);
  }

  const response = await axios.all(posts);
  const postData = response.map((res) => res.data);
  const result = postData.flat();

  return result;
}

// Get all candidates
async function getCandidates() {
  const numPosts = await getNumCandidates();
  const posts = await fetchPosts(numPosts);

  return posts;
}

// Set candidates to payload
export function* fetchCandidates() {
  try {
    const response = yield call(getCandidates);

    yield put({ type: SAVE_CANDIDATES, payload: response });
  } catch (err) {
    console.log(err.message);
  }
}

export default function* sagas() {
  yield takeEvery(LOAD_CANDIDATES, fetchCandidates);
}
