import React, { useMemo } from 'react';
import Typography from '@namia/typography';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Card, Row, List, Pagination } from 'antd';
import Loader from 'react-loader-spinner';
import { useQuery } from '../../helpers';
import { ROW_GUTTER, NO_RESULTS_TEXT } from '../../constants';
import { filterCandidate, sortCandidate } from './helpers';

import Social from '../Social';
import {
  CandidateWrapper,
  PaginationWrapper,
  SingleCandidate,
  CandidateHeader,
  LoadingWrapper,
  LoadingWrapperIcon,
  PlaceholderWrapper,
} from './styles';
import { setFilters } from '../Filters/actions';
import { PrintCandidate } from './PrintCandidate';

// check if url includes "useShadow"-parameter
const shadowToUrl = window.location.search.includes('useShadow')
  ? '?seravo_shadow=a3f942'
  : '';

/* eslint-disable react/prop-types */
function Candidates({ candidate }) {
  const { print } = useQuery();
  const data = [
    {
      title: candidate.kunta,
    },
    {
      title: candidate.ammattiliitto,
    },
    {
      title: candidate.puolue,
    },
  ];
  const { matches } = window.matchMedia('print');
  const { printMode } = useSelector((state) => state.share);
  const isPrint = matches || printMode;

  return (
    <Col
      xs={isPrint ? 8 : 22}
      sm={isPrint ? 8 : 11}
      md={isPrint ? 8 : 11}
      lg={isPrint ? 8 : 7}
      className="candidate"
      key={candidate.id}
    >
      <CandidateWrapper print={print}>
        <Link to={`/${candidate.slug}`}>
          <Card
            size="small"
            hoverable
            title={candidate.alue}
            extra={candidate.ehdokasnumero}
            cover={
              candidate.fimg_url ? (
                <div
                  style={{
                    backgroundImage: `url(${candidate.fimg_url}${shadowToUrl})`,
                    backgroundSize: 'cover',
                    height: 450,
                    width: 'auto',
                    backgroundPosition: 'center',
                  }}
                />
              ) : (
                <PlaceholderWrapper>
                  <svg
                    width="60"
                    height="64"
                    viewBox="0 0 60 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M57.0686 51.6562C55.5948 48.1654 53.4561 44.9946 50.7717 42.3203C48.0954 39.6384 44.925 37.5 41.4357 36.0234C41.4045 36.0078 41.3732 36 41.342 35.9844C46.2092 32.4688 49.3732 26.7422 49.3732 20.2812C49.3732 9.57812 40.7014 0.90625 29.9982 0.90625C19.2951 0.90625 10.6232 9.57812 10.6232 20.2812C10.6232 26.7422 13.7873 32.4687 18.6545 35.9922C18.6232 36.0078 18.592 36.0156 18.5607 36.0312C15.0607 37.5078 11.9201 39.625 9.22481 42.3281C6.54285 45.0044 4.40453 48.1748 2.92793 51.6641C1.47732 55.0801 0.694976 58.7425 0.623242 62.4531C0.621157 62.5365 0.635782 62.6195 0.666257 62.6971C0.696731 62.7747 0.742437 62.8455 0.800683 62.9052C0.858928 62.9649 0.928535 63.0123 1.0054 63.0447C1.08227 63.0771 1.16483 63.0938 1.24824 63.0938H5.93574C6.27949 63.0938 6.55293 62.8203 6.56074 62.4844C6.71699 56.4531 9.13887 50.8047 13.4201 46.5234C17.8498 42.0938 23.7326 39.6562 29.9982 39.6562C36.2639 39.6562 42.1467 42.0938 46.5764 46.5234C50.8576 50.8047 53.2795 56.4531 53.4357 62.4844C53.4436 62.8281 53.717 63.0938 54.0607 63.0938H58.7482C58.8316 63.0938 58.9142 63.0771 58.9911 63.0447C59.068 63.0123 59.1376 62.9649 59.1958 62.9052C59.254 62.8455 59.2998 62.7747 59.3302 62.6971C59.3607 62.6195 59.3753 62.5365 59.3732 62.4531C59.2951 58.7188 58.5217 55.0859 57.0686 51.6562ZM29.9982 33.7188C26.4123 33.7188 23.0373 32.3203 20.4982 29.7812C17.9592 27.2422 16.5607 23.8672 16.5607 20.2812C16.5607 16.6953 17.9592 13.3203 20.4982 10.7812C23.0373 8.24219 26.4123 6.84375 29.9982 6.84375C33.5842 6.84375 36.9592 8.24219 39.4982 10.7812C42.0373 13.3203 43.4357 16.6953 43.4357 20.2812C43.4357 23.8672 42.0373 27.2422 39.4982 29.7812C36.9592 32.3203 33.5842 33.7188 29.9982 33.7188Z"
                      fill="#EAEAEB"
                    />
                  </svg>
                </PlaceholderWrapper>
              )
            }
          >
            <SingleCandidate>
              <CandidateHeader>
                <Typography
                  type="basic"
                  elementType="h3"
                  size={isPrint ? 2 : 3}
                >
                  {candidate.title.rendered}
                  {!!candidate.ika && candidate.ika !== '0' && (
                    <>, {candidate.ika}</>
                  )}
                </Typography>
                <Typography type="basic" elementType="p" size={isPrint ? 1 : 2}>
                  {candidate.nimike}
                </Typography>
              </CandidateHeader>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <svg
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="6.5" cy="7" r="6.5" fill="#C4C4C4" />
                        </svg>
                      }
                      title={item.title}
                    />
                  </List.Item>
                )}
              />
              <Social candidate={candidate} />
            </SingleCandidate>
          </Card>
        </Link>
      </CandidateWrapper>
    </Col>
  );
}

export default function CandidatesComponent() {
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.candidates);
  const filters = useSelector((state) => state.filters);
  const loading = !candidates;
  const { print } = useQuery();

  let { minValue, maxValue } = filters;

  const { currentPage } = filters;

  // On fist load when filters are not applied
  if (!minValue || !maxValue) {
    minValue = 0;
    maxValue = 9;
  }

  // Hide content and hero.
  const $phpContent = document.getElementsByClassName('main__content')[0];
  if ($phpContent) {
    $phpContent.style.display = 'block';
  }

  // Pagination logic
  const handleChange = (value) => {
    if (value <= 1) {
      dispatch(
        setFilters({ ...filters, minValue: 0, maxValue: 9, currentPage: 1 })
      );
    } else {
      dispatch(
        setFilters({
          ...filters,
          minValue: value * 9 - 9,
          maxValue: value * 9,
          currentPage: value,
        })
      );
    }
  };

  const candidatesFiltered = useMemo(
    () =>
      candidates
        ? candidates
            .filter((c) => filterCandidate(c, filters))
            .sort(sortCandidate)
        : [],
    [candidates, filters]
  );

  const { matches } = window.matchMedia('print');
  const { printMode } = useSelector((state) => state.share);
  const isPrint = matches || printMode;

  const renderedCandidates = useMemo(
    () => candidatesFiltered.slice(minValue, maxValue),
    [candidatesFiltered, minValue, maxValue]
  );

  if (loading) {
    return (
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
        <Col md={21} xs={22}>
          <LoadingWrapperIcon>
            <Loader
              type="Oval"
              color="#B18EC2"
              height={48}
              width={48}
              timeout={3000} // 3 secs
            />
          </LoadingWrapperIcon>
        </Col>
      </Row>
    );
  }

  // Sort.
  if (!renderedCandidates || renderedCandidates.length === 0) {
    return (
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
        <Col md={21} xs={22}>
          <LoadingWrapper>
            <Typography type="basic" elementType="p" size="3">
              {NO_RESULTS_TEXT}
            </Typography>
          </LoadingWrapper>
        </Col>
      </Row>
    );
  }

  if (isPrint) {
    return (
      <table className="print__candidates">
        {candidatesFiltered.map((candidate) => (
          <PrintCandidate candidate={candidate} key={candidate.id} />
        ))}
      </table>
    );
  }

  return (
    <>
      <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
        {renderedCandidates.map((candidate) => (
          <Candidates candidate={candidate} key={candidate.id} />
        ))}
      </Row>

      {!print && (
        <Row gutter={[ROW_GUTTER, ROW_GUTTER]}>
          <Col md={22}>
            <PaginationWrapper>
              <Pagination
                current={currentPage}
                defaultPageSize={9} // default size of page
                onChange={(e) => handleChange(e)}
                total={candidatesFiltered.length} // total number of card data available
              />
            </PaginationWrapper>
          </Col>
        </Row>
      )}
    </>
  );
}
