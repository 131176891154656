import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@namia/typography';
import { Tooltip } from 'antd';
import { clickPrint } from './actions';
import {
  ShareContainer,
  ShareBtn,
  ShareBtnBlue,
  ShareIcon,
  ShareActive,
  ShareIcons,
  SingleShareWrapper,
  ThankYou,
} from './styles';

import {
  PRINT_FILENAME,
  SHARE_TEXT,
  SHARE_LINK,
  WP_ENV,
} from '../../constants';
import { updateSearchParams } from './helpers';

/* eslint-disable */
/**
 * Gets the AWS Puppeteer print link.
 */
const getPrintLink = () => {
  const staging = WP_ENV === 'staging' ? 'staging=true&' : '';
  const host =
    WP_ENV === 'development'
      ? 'http://localhost:3000/pdf'
      : 'https://6c031kfojl.execute-api.eu-north-1.amazonaws.com/production/pdf';
  const printLink =
    window?.location?.href.indexOf('?') > 0
      ? `${host}?${staging}url=${encodeURIComponent(
          `${window?.location?.href}&print=true`
        )}&name=${PRINT_FILENAME}`
      : `${host}?${staging}url=${encodeURIComponent(
          `${window?.location?.href}?print=true`
        )}&name=${PRINT_FILENAME}`;

  return printLink;
};

function Share(props) {
  const filters = useSelector((state) => state.filters);
  const { printMode } = useSelector((state) => state.share);
  // `useParams` hook to access url params.
  const { candidate } = props;
  const dispatch = useDispatch();
  const [thankYouText, setThankYouText] = useState(SHARE_LINK);
  const [url, setUrl] = useState(null);
  const [isActive, setIsActive] = useState(false);

  function activateShare() {
    // Is it list view or single candidate?
    if (!candidate) {
      // Set filters to url.
      updateSearchParams({ filters });
    }
    const newUrl = window.location.href;
    setUrl(newUrl);
    setIsActive(true);
  }

  function handleLinkCopy() {
    // Because of browser compatibility.
    // Create and attach temp dom element for "selecting" and copying it to clipboard.
    const textField = document.createElement('textarea');
    textField.innerText = window.location.href;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setThankYouText('Linkki kopioitu leikepöydälle!');
  }

  return (
    <ShareContainer
      className={candidate ? 'Candidate share-container' : 'share-container'}
    >
      {isActive && (
        <ShareActive className={`${candidate ? 'Candidate' : ''}`}>
          <ShareIcons>
            <ThankYou>
              <Typography type="basic" elementType="h4" size="1">
                {SHARE_TEXT}
              </Typography>
              <Typography type="basic" elementType="p" size="1">
                {thankYouText}
              </Typography>
            </ThankYou>
            <SingleShareWrapper
              onClick={() => {
                window.open(
                  `https://www.facebook.com/share.php?u=${url}`,
                  '_blank'
                );
                setThankYouText('Kiitos!');
              }}
            >
              <Typography type="basic" elementType="p" size="1">
                Facebook
              </Typography>
              <ShareIcon className="share-icon">
                <svg
                  width="11"
                  height="16"
                  viewBox="0 0 11 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.52293 16L3.5 9H0.5V6H3.5V4C3.5 1.3008 5.17151 0 7.57938 0C8.73277 0 9.72406 0.08587 10.0129 0.12425V2.94507L8.34296 2.94583C7.03343 2.94583 6.77987 3.5681 6.77987 4.48124V6H10.5L9.5 9H6.77986V16H3.52293Z"
                    fill="#B18EC2"
                  />
                </svg>
              </ShareIcon>
            </SingleShareWrapper>
            <SingleShareWrapper
              onClick={() => {
                window.open(`https://x.com/intent/tweet?text=${url}`, '_blank');
                setThankYouText('Kiitos!');
              }}
            >
              <Typography type="basic" elementType="p" size="1">
                X
              </Typography>
              <ShareIcon className="share-icon">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 300 300.251"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" />
                </svg>
              </ShareIcon>
            </SingleShareWrapper>

            <SingleShareWrapper
              onClick={() => {
                window.open(
                  `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
                  '_blank'
                );
                setThankYouText('Kiitos!');
              }}
            >
              <Typography type="basic" elementType="p" size="1">
                LinkedIn
              </Typography>
              <ShareIcon className="share-icon">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.625 14.625H15.375C14.9602 14.625 14.625 14.9602 14.625 15.375V32.625C14.625 33.0398 14.9602 33.375 15.375 33.375H32.625C33.0398 33.375 33.375 33.0398 33.375 32.625V15.375C33.375 14.9602 33.0398 14.625 32.625 14.625ZM20.1867 30.6023H17.4047V21.6539H20.1867V30.6023ZM18.7969 20.4305C18.478 20.4305 18.1662 20.3359 17.901 20.1587C17.6358 19.9815 17.4292 19.7297 17.3071 19.435C17.1851 19.1404 17.1531 18.8162 17.2154 18.5034C17.2776 18.1906 17.4312 17.9033 17.6567 17.6778C17.8822 17.4522 18.1695 17.2987 18.4823 17.2365C18.7951 17.1742 19.1193 17.2062 19.414 17.3282C19.7086 17.4503 19.9604 17.6569 20.1376 17.9221C20.3148 18.1873 20.4094 18.499 20.4094 18.818C20.407 19.7086 19.6852 20.4305 18.7969 20.4305ZM30.6023 30.6023H27.8227V26.25C27.8227 25.2117 27.8039 23.8781 26.3766 23.8781C24.9305 23.8781 24.7078 25.0078 24.7078 26.175V30.6023H21.9305V21.6539H24.5977V22.8773H24.6352C25.0055 22.1742 25.9125 21.4312 27.2672 21.4312C30.0844 21.4312 30.6023 23.2852 30.6023 25.6945V30.6023Z"
                    fill="#B18EC2"
                  />
                </svg>
              </ShareIcon>
            </SingleShareWrapper>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={() => dispatch(clickPrint())}>
              <SingleShareWrapper print>
                <Typography type="basic" elementType="p" size="1">
                  {printMode ? 'Ladataan, odota...' : 'Tulosta'}
                </Typography>
                <ShareIcon className="share-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1562 0.8125C15.1562 0.709375 15.0719 0.625 14.9688 0.625H5.03125C4.92812 0.625 4.84375 0.709375 4.84375 0.8125V4.28125H15.1562V0.8125ZM17.9688 5.78125H2.03125C0.995312 5.78125 0.15625 6.62031 0.15625 7.65625V15.3438C0.15625 15.7586 0.491406 16.0938 0.90625 16.0938H4.84375V19.1875C4.84375 19.2906 4.92812 19.375 5.03125 19.375H14.9688C15.0719 19.375 15.1562 19.2906 15.1562 19.1875V16.0938H19.0938C19.5086 16.0938 19.8438 15.7586 19.8438 15.3438V7.65625C19.8438 6.62031 19.0047 5.78125 17.9688 5.78125ZM13.5625 17.7812H6.4375V11.3125H13.5625V17.7812ZM17.4062 9.34375C17.4062 9.44687 17.3219 9.53125 17.2188 9.53125H16.2812C16.1781 9.53125 16.0938 9.44687 16.0938 9.34375V8.40625C16.0938 8.30312 16.1781 8.21875 16.2812 8.21875H17.2188C17.3219 8.21875 17.4062 8.30312 17.4062 8.40625V9.34375Z"
                      fill="#B18EC2"
                    />
                  </svg>
                </ShareIcon>
              </SingleShareWrapper>
            </a>
            <SingleShareWrapper onClick={handleLinkCopy}>
              <Tooltip trigger="click" title="Linkki kopioitu leikepöydällesi">
                <span>
                  <Typography type="basic" elementType="p" size="1">
                    Kopioi linkki
                  </Typography>
                </span>
              </Tooltip>

              <ShareIcon className="share-icon">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.79995 10.7L6.49995 11.4L7.89995 10L7.19995 9.3C6.19995 8.3 6.19995 6.7 7.19995 5.7L10.2 2.7C11.2 1.7 12.8 1.7 13.8 2.7C14.8 3.7 14.8 5.3 13.8 6.3L13.1 7L14.5 8.4L15.2 7.7C17 5.9 17 3.1 15.2 1.3C14.3 0.5 13.2 0 12 0C10.8 0 9.69995 0.4 8.79995 1.3L5.79995 4.3C3.99995 6.1 3.99995 8.9 5.79995 10.7ZM1.79995 14.7C2.69995 15.5 3.79995 16 4.99995 16C6.19995 16 7.39995 15.5 8.19995 14.7L11.2 11.7C13 9.9 13 7.1 11.2 5.3L10.5 4.6L9.09995 6L9.79995 6.7C10.8 7.7 10.8 9.3 9.79995 10.3L6.79995 13.3C5.79995 14.3 4.19995 14.3 3.19995 13.3C2.19995 12.3 2.19995 10.7 3.19995 9.7L3.89995 9L2.49995 7.6L1.79995 8.3C-4.88013e-05 10.1 -4.88013e-05 12.9 1.79995 14.7Z"
                    fill="#B18EC2"
                  />
                </svg>
              </ShareIcon>
            </SingleShareWrapper>
          </ShareIcons>
        </ShareActive>
      )}
      {!isActive ? (
        <ShareBtn onClick={activateShare}>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.625 13.5625C13.957 13.5625 13.3406 13.7969 12.8578 14.1883L8.00156 10.675C8.08288 10.2287 8.08288 9.77133 8.00156 9.325L12.8578 5.81172C13.3406 6.20312 13.957 6.4375 14.625 6.4375C16.1766 6.4375 17.4375 5.17656 17.4375 3.625C17.4375 2.07344 16.1766 0.8125 14.625 0.8125C13.0734 0.8125 11.8125 2.07344 11.8125 3.625C11.8125 3.89687 11.85 4.15703 11.9227 4.40547L7.31016 7.74531C6.62578 6.83828 5.53828 6.25 4.3125 6.25C2.24062 6.25 0.5625 7.92812 0.5625 10C0.5625 12.0719 2.24062 13.75 4.3125 13.75C5.53828 13.75 6.62578 13.1617 7.31016 12.2547L11.9227 15.5945C11.85 15.843 11.8125 16.1055 11.8125 16.375C11.8125 17.9266 13.0734 19.1875 14.625 19.1875C16.1766 19.1875 17.4375 17.9266 17.4375 16.375C17.4375 14.8234 16.1766 13.5625 14.625 13.5625ZM14.625 2.40625C15.2977 2.40625 15.8438 2.95234 15.8438 3.625C15.8438 4.29766 15.2977 4.84375 14.625 4.84375C13.9523 4.84375 13.4062 4.29766 13.4062 3.625C13.4062 2.95234 13.9523 2.40625 14.625 2.40625ZM4.3125 12.0625C3.17578 12.0625 2.25 11.1367 2.25 10C2.25 8.86328 3.17578 7.9375 4.3125 7.9375C5.44922 7.9375 6.375 8.86328 6.375 10C6.375 11.1367 5.44922 12.0625 4.3125 12.0625ZM14.625 17.5938C13.9523 17.5938 13.4062 17.0477 13.4062 16.375C13.4062 15.7023 13.9523 15.1562 14.625 15.1562C15.2977 15.1562 15.8438 15.7023 15.8438 16.375C15.8438 17.0477 15.2977 17.5938 14.625 17.5938Z"
              fill="#B18EC2"
            />
          </svg>
        </ShareBtn>
      ) : (
        <ShareBtnBlue
          onClick={() => {
            setIsActive(false);
            setThankYouText(SHARE_LINK);
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.21376 8L15.3661 0.666406C15.4692 0.544531 15.3825 0.359375 15.2231 0.359375H13.3528C13.2427 0.359375 13.1372 0.408594 13.0645 0.492969L7.99032 6.54219L2.91611 0.492969C2.84579 0.408594 2.74032 0.359375 2.62782 0.359375H0.757512C0.598137 0.359375 0.511418 0.544531 0.614543 0.666406L6.76689 8L0.614543 15.3336C0.591442 15.3608 0.576622 15.394 0.571842 15.4293C0.567061 15.4647 0.572521 15.5006 0.587574 15.533C0.602627 15.5653 0.626641 15.5926 0.656764 15.6117C0.686886 15.6308 0.721853 15.6408 0.757512 15.6406H2.62782C2.73798 15.6406 2.84345 15.5914 2.91611 15.507L7.99032 9.45781L13.0645 15.507C13.1349 15.5914 13.2403 15.6406 13.3528 15.6406H15.2231C15.3825 15.6406 15.4692 15.4555 15.3661 15.3336L9.21376 8Z"
              fill="white"
            />
          </svg>
        </ShareBtnBlue>
      )}
    </ShareContainer>
  );
}

Share.defaultProps = {
  candidate: false,
};

export default Share;
