import { delay, select, put, takeEvery } from 'redux-saga/effects';
import { CLICK_PRINT, SET_PRINT_MODE } from './actions';

export function* handleClickPrint() {
  const printMode = yield select(
    (state) => state && state.share && state.share.printMode
  );
  if (printMode) {
    return;
  }
  yield put({ type: SET_PRINT_MODE, payload: true });
  yield delay(800);
  while (true) {
    document.querySelector('body').classList.add('no-scroll');
    const images = document.querySelectorAll('img');
    const loadingStates = Array.from(images)
      .filter((image) => {
        // check if image has loading=lazy attribute and if is, skip it
        if (image.getAttribute('loading') === 'lazy') {
          return false;
        }
        return true;
      })
      .map((image) => image.complete);
    if (loadingStates.every((state) => state)) {
      break;
    }
    yield delay(500);
  }
  // add background image and herobanner to print as img elements so they will be printed
  const parent = document.querySelector('.herobanner');
  const backgroundElement = document.createElement('img');
  backgroundElement.src =
    '/wp-content/themes/aluevaalikone/assets/images/aluevaalitausta.svg';
  backgroundElement.style.width = '100%';
  backgroundElement.style.position = 'absolute';
  backgroundElement.style.left = '0';
  backgroundElement.style.top = '0';
  backgroundElement.style.zIndex = '0';
  // add background image to the last element of the parent
  parent.appendChild(backgroundElement);
  // add herobanner__logo to print
  const logo = document.querySelector('.herobanner__logo');
  const logoElement = document.createElement('img');
  logoElement.src =
    '/wp-content/themes/aluevaalikone/assets/images/aluevaalilogo.svg';
  // append logo to the logo element
  logo.appendChild(logoElement);
  window.print();
  document.querySelector('body').classList.remove('no-scroll');
  // remove backgroundElement and logoElement after print
  backgroundElement.remove();
  logoElement.remove();
  yield put({ type: SET_PRINT_MODE, payload: false });
}

export default function* sagas() {
  yield takeEvery(CLICK_PRINT, handleClickPrint);
}
