// eslint-disable-next-line import/prefer-default-export
export const updateSearchParams = ({ filters }) => {
  // Set filters to url.
  const { haku, kunta, puolue, liitto, alue } = filters;
  const params = new URLSearchParams(window.location.search);
  if (haku) params.set('haku', haku);
  else params.delete('haku');
  if (kunta) params.set('kunta', kunta);
  else params.delete('kunta');
  if (puolue) params.set('puolue', puolue);
  else params.delete('puolue');
  if (liitto) params.set('liitto', liitto);
  else params.delete('liitto');
  if (alue) params.set('alue', alue);
  else params.delete('alue');
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};
