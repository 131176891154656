import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete, Input, Radio, Select } from 'antd';
import { useQuery } from '../../../helpers';
import { FilterSection } from '../styles';
import { getCandidates } from '../../Candidates/selectors';
import {
  ALL,
  NAME_SEARCH,
  MUNICIPALITY_SEARCH,
  MUNICIPALITY_SEARCH_HOLDER,
  COUNTY,
  CHOOSE_COUNTY,
} from '../../../constants';
import kuntaData from './kunnat.json';
import countyData from './counties.json';

const getDefaultSearchType = ({ kuntaValue, countyValue, searchValue }) => {
  if (countyValue) return 'alue';
  if (kuntaValue) {
    return 'kunta';
  }
  if (searchValue) {
    return 'nimi';
  }
  return 'alue';
};

/* eslint-disable react/prop-types */
function Search({
  setParentSearchValue,
  defaultSearchValue,
  defaultKuntaValue,
  setParentKuntaValue,
  defaultCountyValue,
  setParentCountyValue,
}) {
  const { Option } = Select;
  const dataSource = useSelector(getCandidates);

  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [kuntaValue, setKuntaValue] = useState(defaultKuntaValue);
  const [countyValue, setCountyValue] = useState(defaultCountyValue);
  const [searchType, setSearchType] = useState(
    getDefaultSearchType({ kuntaValue, countyValue, searchValue })
  );

  const query = useQuery();
  const { print } = query;

  const listat = kuntaData.map((ob, idx) => ({
    value: ob,
    label: ob,
    key: idx,
  }));

  const options = dataSource.map((ob, idx) => ({
    value: ob.title.rendered,
    label: ob.title.rendered,
    key: idx,
  }));

  return (
    <>
      {!print && (
        <FilterSection>
          <Radio.Group
            onChange={(e) => {
              setSearchType(e.target.value);
              setSearchValue(null);
              setKuntaValue(null);
              setCountyValue(null);
              setParentKuntaValue(null);
              setParentCountyValue(null);
              setParentSearchValue(null);
            }}
            value={searchType}
          >
            <Radio value="alue">{COUNTY}</Radio>
            <Radio value="kunta">{MUNICIPALITY_SEARCH}</Radio>
            <Radio value="nimi">{NAME_SEARCH}</Radio>
          </Radio.Group>
        </FilterSection>
      )}
      {searchType === 'alue' && (
        <Select
          size="large"
          defaultValue={countyValue || CHOOSE_COUNTY}
          onChange={(value) => {
            setCountyValue(value);
            if (!value) {
              setParentCountyValue(null);
            }
          }}
          onSelect={(value) => {
            setParentCountyValue(value);
          }}
          style={{ width: '100%' }}
        >
          <Option value="Kaikki">{ALL}</Option>
          {countyData.map((county) => (
            <Option key={county} value={county}>
              {county}
            </Option>
          ))}
        </Select>
      )}
      {searchType === 'nimi' && (
        <AutoComplete
          style={{
            width: '100%',
          }}
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
            if (!value) {
              setParentSearchValue(null);
            }
          }}
          onSelect={(value) => {
            setParentSearchValue(value);
          }}
          defaultValue={
            options.find((o) => o.id && o.id === searchValue) || undefined
          }
          options={options}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder="Search" />
        </AutoComplete>
      )}
      {searchType === 'kunta' && (
        <AutoComplete
          style={{
            width: '100%',
          }}
          value={kuntaValue}
          onChange={(value) => {
            setKuntaValue(value);
            if (!value) {
              setParentKuntaValue(null);
            }
          }}
          onSelect={(value) => {
            setParentKuntaValue(value);
          }}
          options={listat}
          filterOption={(inputValue, option) =>
            option.label
              .toUpperCase()
              .indexOf(inputValue.toString().toUpperCase()) !== -1
          }
        >
          <Input.Search size="large" placeholder={MUNICIPALITY_SEARCH_HOLDER} />
        </AutoComplete>
      )}
    </>
  );
}

export default Search;
